.c-container-root {
  margin: 0 auto;
  padding: 0 3rem;
  width: 100%;
}

.c-container-root.c-container-width-default {
  max-width: 100.6rem;
}

.c-container-root.c-container-width-large {
  max-width: 124.6rem;
}

.c-container-root + .c-container-root {
  margin-top: 16rem;
}
