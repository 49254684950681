.c-pricing-root {
  background-color: var(--white-transparent);
  border-radius: 1.6rem;
  display: flex;
  flex-direction: row;
  max-width: 100.6rem;
  overflow: hidden;
}

.c-pricing-image {
  max-height: 100%;
  max-width: 46.8rem;
  -o-object-fit: cover;
     object-fit: cover;
  width: 46.8rem;
}

.c-pricing-content {
  flex-grow: 1;
  padding: 4rem;
}

.c-pricing-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 3.2rem;
}

.c-pricing-title {
  color: var(--white);
  font-size: 3.6rem;
  font-weight: var(--semibold);
  margin: 0;
}

.c-pricing-currencies {
  display: grid;
  grid-gap: 0.8rem;
  grid-template-columns: repeat(3, 1fr);
}

.c-pricing-currency {
  display: flex;
}

.c-pricing-currency-input {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.c-pricing-currency-text {
  align-items: center;
  background-color: var(--grey);
  border: 0;
  border-radius: 1.5rem;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 1.4rem;
  font-weight: var(--semibold);
  justify-content: center;
  padding: 0.6rem 1rem;
  text-align: center;
  transition: background-color 0.2s;
}

.c-pricing-currency-text:hover,
.c-pricing-currency-input:checked ~ .c-pricing-currency-text {
  background-color: var(--azure);
}

.c-pricing-currency-input:focus-visible ~ .c-pricing-currency-text {
  color: var(--white);
  outline: var(--focus-outline);
}

.c-pricing-range-label {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.c-pricing-range-label-total-session {
  display: flex;
  flex-direction: column;
}

.c-pricing-range-label-total-session--number,
.c-pricing-range-slider-value {
  color: var(--white);
  font-size: 2.4rem;
  font-weight: var(--bold);
  text-transform: uppercase;
}

.c-pricing-range-label-total-session--subtitle,
.c-pricing-range-slider-label {
  color: var(--cerebral-grey);
  font-size: 1.6rem;
  font-weight: var(--regular);
  padding-top: 0.7rem;
}

.c-pricing-range-slider {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.c-pricing-note {
  color: var(--grey);
  font-size: 1.1rem;
  font-style: italic;
  margin-top: 0.8rem;
  visibility: hidden;
}

.c-pricing-root.c-pricing-converted .c-pricing-note {
  visibility: visible;
}

.c-pricing-services {
  margin-top: 2.6rem;
}

.c-pricing--service-root {
  align-items: center;
  color: var(--cerebral-grey);
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  font-weight: var(--regular);
  list-style: none;
  margin-bottom: 1.1rem;
}

.c-pricing--service-icon {
  background-color: var(--shamrock);
  border-radius: 50%;
  height: 2.2rem;
  margin-right: 0.8rem;
  padding: 0.5rem 0.4rem;
  width: 2.2rem;
}

/*********** Baseline, reset styles ***********/

input[type="range"].c-pricing-range-input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
}

/* Removes default focus */

input[type="range"].c-pricing-range-input:focus {
  outline: none;
}

/******** Chrome, Safari, Opera and Edge Chromium styles ********/

/* slider track */

input[type="range"].c-pricing-range-input::-webkit-slider-runnable-track {
  background-color: var(--tundora);
  border-radius: 0.5rem;
  height: 0.8rem;
  width: 100%;
}

/* slider thumb */

input[type="range"].c-pricing-range-input::-webkit-slider-thumb {
  -webkit-appearance: none;
          appearance: none;
  background: url("../../../app/assets/images/range.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  border: none;
  border-radius: 50%;
  height: 3.1rem;
  margin-top: -1.2rem;
  width: 3.1rem;
}

input[type="range"].c-pricing-range-input:focus::-webkit-slider-thumb {
  outline: 3px solid var(--ribbon-blue-alpha);
  outline-offset: 0.125rem;
}

/*********** Firefox styles ***********/

/* slider track */

input[type="range"].c-pricing-range-input::-moz-range-track {
  background-color: var(--tundora);
  border-radius: 0.5rem;
  height: 0.8rem;
  width: 100%;
}

/* slider thumb */

input[type="range"].c-pricing-range-input::-moz-range-thumb {
  background: url("../../../app/assets/images/range.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  border: none;
  border-radius: 50%;
  height: 3.1rem;
  width: 3.1rem;
}

input[type="range"].c-pricing-range-input:focus::-moz-range-thumb {
  outline: 3px solid var(--ribbon-blue-alpha);
  outline-offset: 0.125rem;
}

/* Responsive */

@media (max-width: 900px) {
  .c-pricing-image {
    width: clamp(20rem, 100%, 46.8rem);
  }

  .c-pricing-title {
    font-size: clamp(1.8rem, 100%, 3.6rem);
  }

  .c-pricing-range-label-total-session--number,
  .c-pricing-range-slider-value {
    font-size: clamp(1.8rem, 100%, 2.4rem);
  }

  .c-pricing-range-label-total-session--subtitle,
  .c-pricing-range-slider-label {
    font-size: clamp(1rem, 100%, 1.4rem);
  }

  .c-pricing--service-root {
    font-size: clamp(1.2rem, 100%, 1.4rem);
  }
}

@media (max-width: 768px) {
  .c-pricing-root {
    flex-direction: column;
  }

  .c-pricing-image {
    height: 20rem;
    max-width: 70.8rem;
    width: 100%;
  }

  .c-pricing-content {
    padding: 3rem 1.5rem;
  }

  .c-pricing--service-icon {
    height: clamp(1.8rem, 100%, 2rem);
    width: clamp(1.8rem, 100%, 2rem);
  }
}
