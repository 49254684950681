.c-description-card-root {
  display: flex;
}

.c-image-root.c-description-card-image {
  border-radius: 1.6rem;
  display: block;
  height: 50.3rem;
  margin-right: 6rem;
  -o-object-fit: cover;
     object-fit: cover;
  width: 50.3rem;
}

.c-description-card-content {
  font-size: 1.8rem;
  line-height: 1.5;
  padding-top: 6rem;
}

.c-description-card-button {
  margin-top: 1.2rem;
}

@media (max-width: 991.98px) {
  .c-description-card-root {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-right: 0;
    padding: 0;
  }

  .c-image-root.c-description-card-image {
    border-radius: 1.6rem;
    height: 40rem;
    margin: 0;
    width: 100%;
  }

  .c-description-card-content {
    font-size: 1.6rem;
    padding-top: 3rem;
  }
}
