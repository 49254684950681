.c-social-root {
  align-items: center;
  background-color: var(--azure);
  border-radius: 50%;
  display: flex;
  height: 3.3rem;
  justify-content: center;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  width: 3.3rem;
}

.c-social-root:hover {
  background-color: var(--science-blue);
}

.c-social-root:focus-visible {
  border-radius: 50%;
  outline: var(--focus-outline);
}

.c-social-root:active {
  background-color: var(--midnight-blue);
}

.c-social-icon {
  --icon-size: 1.5rem;

  color: var(--white);
}
