.c-header-root {
  --menu-height: 6rem;

  -webkit-backdrop-filter: blur(2rem) brightness(0.4);

          backdrop-filter: blur(2rem) brightness(0.4);
  color: var(--white);
  font-size: 1.4rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 402;
}

.c-header-container {
  height: var(--menu-height);
}

.c-header-logo {
  height: 2.6rem;
  width: 12.5rem;
}

.c-header-menu {
  font-weight: var(--regular);
  list-style: none;
  margin: 0;
  text-decoration: none;
  text-transform: none;
}

.c-header-menu-item .c-header--item-root {
  padding: 1rem 0;
  text-decoration: none;
}

.c-header-menu-item + .c-header-button {
  margin-left: 1.5rem;
}

.c-header--item-root:focus-visible {
  color: var(--white);
  outline: var(--focus-outline);
}

.c-header-toggle {
  display: none;
}

/******* RESPONSIVE ********/

@media screen and (min-width: 1090px) {
  .c-header-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 6rem;
  }

  .c-header-wrapper {
    align-items: center;
    display: flex;
  }

  .c-header-nav {
    align-items: center;
    display: flex;
  }

  .c-header-menu {
    align-items: center;
    display: flex;
    font-size: 1.4rem;
    justify-content: space-between;
  }

  .c-header-menu-item,
  .c-header-menu-locales {
    display: inline-block;
    list-style: outside none none;
    padding: 0;
  }

  .c-header-menu-item {
    margin: 0 1.5em;
  }

  .c-header-menu-locales {
    margin-left: 1.2em;
  }

  .c-header-menu-item:last-child {
    margin-right: 3rem;
  }

  .c-header-menu-item .c-header--item-root {
    color: var(--silver);
    position: relative;
  }

  .c-header-menu-item .c-header--item-root::before,
  .c-header-menu-item .c-header--item-root::after {
    position: absolute;
    transition: all 0.35s ease;
  }

  .c-header-menu-item .c-header--item-root::before {
    background: var(--azure);
    border-radius: 0 0 0.2rem 0.2rem;
    content: "";
    display: block;
    height: 0.4rem;
    top: -1rem;
    width: 0%;
  }

  .c-header-menu-item .c-header--item-root::after {
    color: var(--white);
    content: attr(data-hover);
    left: 0;
    max-width: 0%;
    overflow: hidden;
    position: absolute;
    top: 1.2rem;
    white-space: nowrap;
  }

  .c-header-menu-item .c-header--item-root:hover::before {
    opacity: 1;
    width: 100%;
  }

  .c-header-menu-item .c-header--item-root:hover::after {
    max-width: 100%;
  }
}

@media screen and (max-width: 1090px) {
  body.has-header-menu-open {
    overflow: hidden;
  }

  .c-header-container {
    padding: 1.7rem 3rem;
  }

  .c-header-menu {
    -webkit-backdrop-filter: blur(3rem);
            backdrop-filter: blur(3rem);
    display: flex;
    flex-direction: column;
    height: 100vh;
    left: 0;
    padding-left: 0;
    padding-top: 4rem;
    position: absolute;
    text-align: center;
    top: var(--menu-height);
    transform: translateX(100%);
    transition: transform 0.5s ease-in-out;
    width: 100%;
  }

  .c-header-menu-open {
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
    background-color: var(--log-cabin-transparent);
  }

  .c-header-menu-open .c-header-menu {
    transform: translateX(0);
  }

  .c-header--item-root {
    color: var(--white);
    display: block;
    font-size: 1.6rem;
    padding: 1.6rem 0;
    text-align: center;
    text-decoration: none;
    width: 100%;
  }

  .c-header--item-root:hover {
    color: var(--ribbon-blue-alpha);
    transition: all 0.2s ease-in-out;
  }

  .c-header-menu .c-header-button {
    font-size: 1.5rem;
    margin: 1.6rem 0;
  }

  .c-header-menu-locales {
    position: absolute;
    right: 7rem;
    top: 50%;
    transform: translateY(-50%);
  }

  /****** Menu Burger ******/

  .c-header-toggle {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: block;
    height: 3.3rem;
    margin-left: 1.6rem;
    outline: none;
    position: absolute;
    right: 3rem;
    top: 50%;
    transform: translateY(-50%);
    width: 3rem;
    z-index: 2;
  }

  .c-header-toggle > span {
    background-color: var(--white);
    border-radius: 12.5rem;
    display: block;
    height: 0.25rem;
    position: relative;
    transition: background-color 0.5s ease-in-out;
    width: 100%;
  }

  .c-header-toggle > span::before,
  .c-header-toggle > span::after {
    background-color: var(--white);
    border-radius: 1.2rem;
    content: "";
    display: block;
    height: 0.25rem;
    transition: all 0.5s ease-in-out;
    width: 100%;
  }

  .c-header-toggle > span::before {
    transform: translateY(-0.7rem);
  }

  .c-header-toggle > span::after {
    margin-top: -0.3rem;
    transform: translateY(0.7rem);
  }

  .c-header-root.c-header-menu-open .c-header-toggle > span {
    background-color: transparent;
  }

  .c-header-root.c-header-menu-open .c-header-toggle > span::before {
    transform: rotateZ(45deg) translateY(0);
  }

  .c-header-root.c-header-menu-open .c-header-toggle > span::after {
    transform: rotateZ(-45deg) translateY(0);
  }
}
