.c-accordion--item-title {
  margin: 0;
}

.c-accordion--item-title:not(:first-child) {
  border-top: 1px solid var(--white-transparent);
}

.c-accordion--item-button {
  --icon-size: 1.4rem;

  align-items: center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  border: 0;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 1.8rem;
  font-weight: var(--semibold);
  justify-content: space-between;
  outline: 0;
  padding: 1rem 0;
  position: relative;
  width: 100%;
}

.c-accordion--item-button::after {
  bottom: 0;
  content: "";
  display: "block";
  left: 0;
  position: absolute;
  right: 0;
}

.c-accordion--item-button[aria-expanded="true"] .c-accordion--item-icon {
  transform: rotate(90deg);
}

.c-accordion--item-button:hover {
  --icon-color: var(--shamrock);
}

.c-accordion--item-button:hover::after {
  border-top-color: var(--tundora);
}

.c-accordion--item-icon {
  transition: fill 0.2s ease, transform 0.2s ease;
}

.c-accordion--item-content {
  color: var(--cerebral-grey);
  line-height: 1.6;
  margin-bottom: 2.4rem;
}

.c-accordion--item-content + .c-accordion--item-title {
  margin-top: 1.4rem;
  padding-top: 1.4rem;
}
