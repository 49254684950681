.c-images-grid-root {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 1fr) 2fr 1fr;
  margin: 0 auto;
  margin-top: 6rem;
}

.c-images-grid-image:nth-child(1) {
  grid-area: 1 / 1 / 5 / 6;
}

.c-images-grid-image:nth-child(2) {
  grid-area: 1 / 6 / 5 / 9;
}

.c-images-grid-image:nth-child(3) {
  grid-area: 5 / 1 / 8 / 5;
}

.c-images-grid-image:nth-child(4) {
  grid-area: 5 / 5 / 8 / 9;
}

.c-images-grid-credits {
  color: var(--grey);
  font-size: clamp(1.3rem, 1.5vw, 1.4rem);
  padding: 1rem 0;
  text-align: left;
  width: 100%;
}

@media (max-width: 576px) {
  .c-images-grid-root {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(8, 1fr);
  }

  .c-images-grid-image:nth-child(1) {
    grid-area: 1 / 1 / 3 / 6;
  }

  .c-images-grid-image:nth-child(2) {
    grid-area: 3 / 1 / 5 / 6;
  }

  .c-images-grid-image:nth-child(3) {
    grid-area: 5 / 1 / 7 / 6;
  }

  .c-images-grid-image:nth-child(4) {
    grid-area: 7 / 1 / 9 / 6;
  }
}
