/* Use component-local class names and add them to HTML via #class_for(name) helper */

.c-trek--form--group-root {
  line-height: 1.25;
  margin-bottom: 3.2rem;
  max-width: 64rem;
}

.c-trek--form--group-label {
  color: var(--slate12);
  display: block;
  font-weight: var(--semibold);
  margin-bottom: 1.2rem;
}

.c-trek--form--group-hint {
  color: var(--slate11);
  display: block;
  font-size: 0.8em;
  margin-bottom: 1.2rem;
}

.c-trek--form--group-label + .c-trek--form--group-hint {
  margin-top: -0.6rem;
}

.c-trek--form--group-root input[type="datetime-local"],
.c-trek--form--group-root input[type="email"],
.c-trek--form--group-root input[type="number"],
.c-trek--form--group-root input[type="password"],
.c-trek--form--group-root input[type="tel"],
.c-trek--form--group-root input[type="text"],
.c-trek--form--group-root input[type="url"],
.c-trek--form--group-root input[type="date"],
.c-trek--form--group-root select,
.c-trek--form--group-root textarea {
  background-color: var(--slate2);
  border: 1px solid var(--slate8);
  border-radius: 0.4rem;
  color: var(--slate12);
  padding: 1rem 1.6rem;
  width: 100%;
}

.c-trek--form--group-root select {
  max-width: 100%;
  min-width: 16rem;
  width: auto;
}

.c-trek--form--group-root input[name*="color"],
.c-trek--form--group-root input[name*="isbn"] {
  font-family: monospace;
}

.c-trek--form--group-root input[type="number"],
.c-trek--form--group-root input[name*="color"],
.c-trek--form--group-root input[type="date"] {
  max-width: 16rem;
}

.c-trek--form--group-root input[name*="isbn"] {
  max-width: 19rem;
}

.c-trek--form--group-root input[type="date"] {
  color: var(--slate11);
  font-weight: var(--light);
}

/* .root label + input[type="number"] {
  margin-left: 1.6rem;
} */

.c-trek--form--group-root input[type="number"] ~ label {
  margin-left: 3.2rem;
}

.c-trek--form--group-root input[type="radio"] + label,
.c-trek--form--group-root input[type="checkbox"] + label {
  cursor: pointer;
  margin-right: 3.2rem;
  min-width: 5rem;
  padding: 1rem 0.8rem;
}

.c-trek--form--group-root input[type="radio"]:checked + label,
.c-trek--form--group-root input[type="checkbox"]:checked + label {
  color: var(--slate12);
}

.c-trek--form--group-root input:disabled,
.c-trek--form--group-root select:disabled,
.c-trek--form--group-root textarea:disabled {
  border-color: var(--slate6);
  color: var(--slate8);
}

.c-trek--form--group-root input::-moz-placeholder {
  color: var(--slate9);
  opacity: 1;
}

.c-trek--form--group-root input::placeholder {
  color: var(--slate9);
  opacity: 1;
}

.c-trek--form--group-root fieldset {
  border: 0;
  padding: 0;
}

/* multiple */

.c-trek--form--group-root.c-trek--form--group-is-multiple .c-trek--form--group-content {
  display: grid;
  grid-gap: 1.6rem;
  grid-template-columns: repeat(3, 19rem);
  margin-bottom: 1.6rem;
}
