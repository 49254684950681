.c-footer-root {
  border-top: 1px solid var(--tundora);
  padding-bottom: 6rem;
}

.c-footer-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 3rem auto 0;
  max-width: 90rem;
  padding: 0;
}

.c-footer-link a {
  color: var(--white);
  text-decoration: none;
}

.c-footer-link {
  margin-bottom: 2rem;
  margin-right: 3rem;
}

.c-footer-socials {
  display: flex;
  justify-content: center;
  list-style: none;
  margin-bottom: 0;
  margin-top: 2rem;
  padding: 0;
}

.c-footer-socials > * {
  margin: 0 0.6rem;
}

.c-footer-button {
  margin-top: -1rem;
}

@media (max-width: 576px) {
  .c-footer-link {
    margin-right: 0;
  }

  .c-footer-button {
    margin-top: 0;
  }

  .c-footer-links {
    flex-direction: column;
    padding: 0;
    text-align: center;
  }
}

@media (max-width: 991.98px) {
  .c-footer-links {
    padding: 0;
  }
}
