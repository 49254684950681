.c-accordion-root {
  background-color: var(--white-transparent);
  border-radius: 1rem;
  padding: 3rem 4rem 4rem;
}

.c-accordion-root [aria-hidden="true"] {
  display: none;
}

.c-accordion-root > :last-child {
  margin-bottom: 0;
}

.c-accordion--item-button {
  font-size: clamp(1.6rem, 1.5vw, 2rem);
  text-align: left;
}

.c-accordion--item-button:hover svg {
  fill: var(--azure);
  flex-shrink: 0;
  margin-left: 0.8rem;
}

.c-accordion--item-content {
  font-size: clamp(1.5rem, 1.5vw, 1.6rem);
}

@media (max-width: 480px) {
  .c-accordion-root {
    padding: 2rem 1.5rem 1.5rem;
  }
}
