.c-diving-center-card-root {
  background-color: var(--white-transparent);
  border-radius: 1rem;
  overflow: hidden;
  padding: 2rem;
  position: relative;
  width: 100%;
}

.c-diving-center-card-image {
  background-color: var(--black-transparent);
  border-radius: 1rem;
  height: 17.5rem;
  margin-bottom: 1.2rem;
  -o-object-fit: cover;
     object-fit: cover;
  overflow: hidden;
  transform-origin: 50% 50%;
  transition: transform 0.4s ease;
}

.c-diving-center-card-background-image {
  background-size: cover;
  height: 100%;
  transition: transform ease-in-out 0.4s;
}

.c-diving-center-card-root:hover .c-diving-center-card-background-image {
  transform: scale(1.1);
}

.c-diving-center-card-content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.c-diving-center-card-place {
  --icon-size: 1.6rem;

  align-items: center;
  color: var(--azure);
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
  font-weight: var(--regular);
  text-transform: capitalize;
}

.c-diving-center-card-place-icon {
  margin-right: 0.8rem;
}

.c-diving-center-card-name {
  color: var(--white);
  font-size: 1.8rem;
  font-weight: var(--semibold);
  line-height: 1.2;
  margin: 1rem 0;
}

.c-diving-center-card-description {
  color: var(--cerebral-grey);
  font-size: 1.6rem;
  font-weight: var(--regular);
  line-height: 1.4;
  margin: 0;
}

.c-diving-center-card-link {
  text-decoration: none;
}

.c-diving-center-card-link::before {
  content: " ";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.c-diving-center-card-link:focus-visible::before {
  border: var(--focus-outline);
}
