/* Use component-local class names and add them to HTML via #class_for(name) helper */

.c-button-root {
  align-items: center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--azure);
  border: none;
  border-radius: 10rem;
  color: var(--white);
  cursor: pointer;
  display: inline-flex;
  font-size: 1.4rem;
  font-weight: var(--regular);
  line-height: 1.25;
  padding: 0.8rem 3rem;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.c-button-root.c-button-size-compact {
  padding: 0.6rem 2.4rem 0.7rem;
}

.c-button-root:hover {
  background-color: var(--science-blue);
}

.c-button-root:focus-visible {
  border-radius: 10rem;
  outline: var(--focus-outline);
}

.c-button-root:active {
  background-color: var(--midnight-blue);
}
