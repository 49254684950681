.c-form--errors-root {
  --icon-size: 1.6rem;

  color: var(--tomato);
  margin-bottom: 3rem;
}

.c-form--errors-message {
  align-items: center;
  display: flex;
  font-weight: var(--semibold);
}

.c-form--errors-icon {
  margin-right: 1.2rem;
}

.c-form--errors-list {
  list-style: none;
  margin: 2rem 0;
  padding: 0;
}

.c-form--errors-item {
  padding: 0.7rem 0;
}
