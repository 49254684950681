.c-info-root.c-info-background-white {
  background-color: var(--white-transparent);
  border-radius: 1rem;
  color: var(--white);
  padding: clamp(2rem, 10vw, 6rem) clamp(2rem, 10vw, 8.7rem)
    clamp(2rem, 10vw, 6rem) clamp(2rem, 10vw, 8.7rem);
}

.c-info-title {
  color: var(--white);
  font-size: clamp(2.2rem, 5vw, 3.2rem);
  font-weight: var(--semibold);
  margin-bottom: 2.4rem;
  margin-top: 0;
}

.c-info-content {
  line-height: 1.6;
}

.c-info-content > p {
  margin: 0 0 2.4rem;
  max-width: 80rem;
}
