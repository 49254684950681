:root {
  --azure: #07f;
  --black-transparent: #0000001f;
  --black: #000;
  --cerebral-grey: #ccc;
  --cod-gray-transparent: #1c1c1c00;
  --cod-gray: #131313;
  --golden-tainoi: #fdc951;
  --grey: #8b8b8b;
  --log-cabin-transparent: #212121cc;
  --log-cabin: #212121;
  --midnight-blue: #003674;
  --mine-shaft: #2d2d2d;
  --ribbon-blue-alpha: #81bcff;
  --ribbon-blue: #006be6;
  --science-blue: #0050ac;
  --shamrock: #3dd58a;
  --silver: #c7c7c7;
  --tomato: #f16a50;
  --tundora: #474747;
  --white-transparent: #ffffff0f;
  --white: #fff;
}
:root {
  --poppins: "Poppins", sans-serif;
  --regular: 400;
  --medium: 500;
  --semibold: 600;
  --bold: 700;
}
:root {
  --focus-outline: 0.3rem solid var(--ribbon-blue-alpha);
}
.with-focus-outline:focus-visible {
  outline: 0.3rem solid #81bcff;
  outline: var(--focus-outline);
}
:root {
  --container-max-width: 140rem;
}
html {
  box-sizing: border-box; /* natural box layout model to all elements */
  font-size: 62.5%; /* for easy rem sizing: 1rem = 10px */
}
/* allow modules to change the box-sizing */
*,
*::before,
*::after {
  box-sizing: inherit;
}
html,
body {
  min-height: 100%;
}
body {
  background-color: #131313;
  background-color: var(--cod-gray);
  color: #ccc;
  color: var(--cerebral-grey);
  font-family: "Poppins", sans-serif;
  font-family: var(--poppins);
  font-size: 1.6rem;
  font-weight: 400;
  font-weight: var(--regular);
}
body.no-scroll {
  overflow: hidden;
}
a {
  color: var(--cyan9);
  transition: color 0.2s ease;
}
a:hover,
a:focus-visible {
  color: var(--cyan11);
}
a:active {
  color: var(--cyan12);
}
a:focus-visible {
  border-radius: 0.2rem;
  outline: 0.3rem solid var(--cyan8-transparent);
}
h2 {
  font-size: 3.6rem;
  font-weight: 700;
  font-weight: var(--bold);
  line-height: 1.25;
}
h3 {
  font-size: 2rem;
  line-height: 1.25;
}
h4 {
  font-size: 1.4rem;
  font-weight: 600;
  font-weight: var(--semibold);
  line-height: 1.25;
}
