.c-diving-center-bar-root {
  align-items: center;
  display: flex;
}

.c-diving-center-bar-body {
  display: flex;
  flex-direction: column;
  padding-left: 1.8rem;
}

.c-diving-center-bar-brand {
  background-color: var(--white);
  border-radius: 5rem;
  height: clamp(8rem, 10vw, 10rem);
  overflow: hidden;
  padding: 1rem;
  width: clamp(8rem, 10vw, 10rem);
}

.c-diving-center-bar-image {
  display: block;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
}

.c-diving-center-bar-title {
  color: var(--white);
  font-size: clamp(2.2rem, 2vw, 3.6rem);
  font-weight: var(--semibold);
  margin-bottom: 0.7rem;
  margin-top: 0.5rem;
}

.c-diving-center-bar-nav {
  display: flex;
}

.c-diving-center-bar-buttons,
.c-diving-center-bar-socials {
  display: flex;
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.c-diving-center-bar-socials {
  margin-left: 0.6rem;
}

.c-diving-center-bar-buttons > *,
.c-diving-center-bar-socials > * {
  margin: 0 0.6rem;
}

.c-diving-center-bar-buttons > :first-child {
  margin-left: 0;
}

.c-diving-center-bar-root.c-diving-center-bar-with-background {
  background-color: var(--white-transparent);
  border-bottom-left-radius: 1.6rem;
  border-bottom-right-radius: 1.6rem;
  padding: 4rem 6rem;
}

@media (max-width: 576px) {
  .c-diving-center-bar-root {
    flex-direction: column;
  }

  .c-diving-center-bar-body {
    margin-top: 1.5rem;
    padding-left: 0;
  }

  .c-diving-center-bar-title {
    margin-bottom: 2rem;
    margin-top: 0.3rem;
    text-align: center;
  }

  .c-diving-center-bar-buttons {
    margin-bottom: 2rem;
  }

  .c-diving-center-bar-socials {
    justify-content: center;
  }

  .c-diving-center-bar-buttons,
  .c-diving-center-bar-socials {
    justify-content: center;
  }

  .c-diving-center-bar-buttons > *,
  .c-diving-center-bar-socials > * {
    margin: 0 0.8rem;
  }

  .c-diving-center-bar-root.c-diving-center-bar-with-background {
    padding: 3rem 2rem;
  }

  .c-diving-center-bar-nav {
    flex-direction: column;
    justify-content: center;
  }
}

@media (max-width: 576px) {
  .c-diving-center-bar-root.c-diving-center-bar-with-cover {
    flex-direction: column;
    position: relative;
    top: -4rem;
  }
}
