.c-diving-center-cards-root {
  display: grid;
  grid-gap: 2rem;
  gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  justify-items: center;
}

.c-diving-center-cards-root.c-diving-center-cards-margin-bottom {
  margin-bottom: 4rem;
}
