.c-heading-root {
  margin-bottom: 4rem;
}

.c-heading-breadcrumbs {
  margin-bottom: 4rem;
}

.c-heading-link {
  align-items: center;
  color: var(--azure);
  display: flex;
  font-weight: var(--medium);
  margin: -1rem;
  margin-bottom: 0.9rem;
  padding: 1rem;
  text-decoration: none;
}

.c-heading-link::after {
  border-right: 0.2rem solid var(--azure);
  border-top: 0.2rem solid var(--azure);
  content: "";
  display: inline-block;
  height: 1rem;
  margin-left: 1.6rem;
  transform: rotate(45deg);
  transition: border-color 0.2s ease-in-out;
  width: 1rem;
}

.c-heading-link:hover {
  color: var(--white);
  transition: all 0.2s ease-in-out;
}

.c-heading-link:hover::after {
  border-color: var(--white);
  transition: all 0.2s ease-in-out;
}

.c-heading-title {
  color: var(--white);
  font-size: 3.2rem;
  font-weight: var(--semibold);
  margin-bottom: 0.9rem;
  margin-top: 0;
}

.c-heading-subtitle {
  color: var(--cerebral-grey);
  font-size: 1.8rem;
  font-weight: var(--regular);
  line-height: 1.6;
  max-width: 75rem;
}

/* modifiers */

.c-heading-root.c-heading-is-centered {
  text-align: center;
}

.c-heading-root.c-heading-is-centered .c-heading-header {
  display: block;
}

.c-heading-root.c-heading-top {
  min-height: 30.3rem;
}

.c-heading-root.c-heading-top .c-heading-header {
  padding-top: 14rem;
}

.c-heading-root.c-heading-margin-bottom {
  margin-bottom: 2.4rem;
}

.c-heading-root.c-heading-has-link .c-heading-header {
  display: flex;
  justify-content: space-between;
}

/* responsive */

@media (max-width: 992px) {
  .c-heading-header {
    flex-flow: column wrap;
  }

  .c-heading-title {
    font-size: clamp(2.2rem, 5vw, 3.2rem);
  }

  .c-heading-subtitle {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}

@media (max-width: 576px) {
  .c-heading-root.c-heading-top {
    height: auto;
  }
}
