.c-separator-root {
  border: none;
  border-top: 1px solid var(--log-cabin);
  margin: 8rem 0;
}

.c-separator-root:first-child {
  margin-top: 0;
}

.c-separator-root:last-child {
  margin-bottom: 0;
}
