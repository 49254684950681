.c-hero-root {
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 90vh;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}

.c-hero-root::before {
  background: linear-gradient(
    0deg,
    rgb(19 19 19 / 100%) 11%,
    rgb(0 0 0 / 0%) 100%
  );
  bottom: 0;
  content: "";
  height: 50%;
  left: 0;
  position: absolute;
  width: 100%;
}

.c-hero-heading {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  transform: translateY(10%);
}

.c-hero-title {
  color: var(--white);
  font-size: 8rem;
  line-height: 1;
  margin: 0;
  padding: 0 6rem;
  text-align: center;
  text-transform: uppercase;
}

.c-hero-subtitle {
  color: var(--white);
  font-size: 1.8rem;
  font-weight: var(--semibold);
  line-height: 1.5;
  margin: 0;
  margin-top: 2rem;
  text-align: center;
  width: 50%;
}

.c-hero-image {
  left: -16rem;
  max-width: 58rem;
  position: absolute;
  top: -18rem;
  width: 100%;
  z-index: 1;
}

.c-hero-button {
  margin-top: 2rem;
  z-index: 1;
}

/* responsive */

@media (max-width: 992px) {
  .c-hero-title {
    font-size: clamp(4rem, 8vw, 8rem);
  }

  .c-hero-subtitle {
    font-size: clamp(1.3rem, 2vw, 1.8rem);
    width: 80%;
  }
}

@media (max-width: 768px) {
  .c-hero-subtitle {
    padding: 0 2rem;
    width: 100%;
  }

  .c-hero-subtitle br {
    display: none;
  }

  .c-hero-image {
    max-width: 40rem;
    top: -12rem;
  }
}

@media (max-width: 576px) {
  .c-hero-title {
    font-size: clamp(3rem, 8vw, 8rem);
    padding: 0 2rem;
  }
}
