.c-testimonial-root {
  background-color: var(--log-cabin);
  border-radius: 1.6rem;
  color: var(--white);
  height: 22rem;
  margin: 0 auto;
  max-width: 54rem;
}

.c-testimonial-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 4rem 4rem 5.4rem;
  position: relative;
}

.c-testimonial-name {
  font-size: 1.6rem;
  font-weight: var(--semibold);
  margin: 0 0 0.8rem;
}

.c-testimonial-text {
  -webkit-box-orient: vertical;
  color: var(--cerebral-grey);
  display: -webkit-box;
  font-size: 1.6rem;
  font-weight: var(--regular);
  -webkit-line-clamp: 5;
  line-height: 1.4;
  margin: 0;
  overflow: hidden;
}

.c-testimonial-image {
  border-radius: 50%;
  height: 6.8rem;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  top: 38%;
  transform: translate(-50%, -50%);
  width: 6.8rem;
}

@media (max-width: 576px) {
  .c-testimonial-image {
    display: none;
  }
}
