.c-diving-center-content {
  line-height: 1.5;
  margin-top: 4rem;
  max-width: 63rem;
}

@media (max-width: 576px) {
  .c-diving-center-root.c-diving-center-with-cover .c-diving-center-content {
    margin-top: 1rem;
  }

  .c-diving-center-root.c-diving-center-with-cover .c-diving-center-content p {
    margin-top: 0;
  }
}
