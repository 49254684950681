.c-features-root {
  display: grid;
  grid-column-gap: 6rem;
  grid-template-columns: repeat(3, 1fr);
}

/* responsive */

@media (max-width: 768px) {
  .c-features-root {
    grid-row-gap: 6rem;
    grid-template-columns: repeat(1, 1fr);
  }
}
