.c-cover-root {
  background-color: var(--black-transparent);
  background-position: center;
  background-size: cover;
  height: 46rem;
  margin-bottom: 4rem;
  padding-top: 9.2rem;
}

@media (max-width: 576px) {
  .c-cover-root {
    height: 23rem;
    margin-bottom: 0;
  }

  .c-cover-breadcrumbs {
    display: none;
  }
}
