.c-map-canvas {
  height: 60rem;
}

.c-map-root.c-map-format-fullscreen .c-map-canvas {
  height: 100vh;
}

@media (max-width: 576px) {
  .c-map-root.c-map-format-fullscreen {
    margin-top: 0;
  }
}

.c-map-marker {
  --icon-color: var(--golden-tainoi);
  --icon-size: 2.4rem;

  transition: fill 0.2s ease;
}

.c-map-marker:hover {
  --icon-color: var(--white);
}

.c-map-image-marker {
  background-color: var(--white);
  border: 0.4rem solid var(--white);
  border-radius: 50%;
  cursor: default;
  height: 7.2rem;
  overflow: hidden;
  padding: 1rem;
  transition: border-color 0.2s ease;
  width: 7.2rem;
}

.c-map-image-marker.leaflet-interactive {
  cursor: default;
}

.c-map-image-marker.c-map-clickable-marker {
  border-color: var(--golden-tainoi);
  cursor: pointer;
}

.c-map-image-marker.c-map-clickable-marker:hover {
  border-color: var(--white);
}

.leaflet-container .c-map-image-marker .c-map-image-marker-icon {
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
}

/* Leaflet */

.c-map-root .leaflet-top.leaflet-left {
  inset: auto 0 0 auto;
  margin-bottom: 6rem;
  margin-right: 6rem;
  z-index: 400;
}

.c-map-root .leaflet-bottom.leaflet-right {
  z-index: 400;
}

.c-map-root .leaflet-control-zoom-in {
  margin-bottom: 0.8rem;
}

.c-map-root .leaflet-bar a {
  align-items: center;
  border-bottom: 0 !important;
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
}

.c-map-root .leaflet-control-zoom-in,
.c-map-root .leaflet-control-zoom-out {
  background-color: var(--azure);
  cursor: pointer;
}

.c-map-root .leaflet-control-zoom-in:hover,
.c-map-root .leaflet-control-zoom-out:hover {
  background-color: var(--science-blue);
  transition: background-color 0.2s ease-in-out;
}

.c-map-root .leaflet-control-zoom-in span,
.c-map-root .leaflet-control-zoom-out span {
  color: var(--white);
  padding-bottom: 0.4rem;
}

.c-map-root a.leaflet-disabled {
  background-color: var(--silver) !important;
  cursor: not-allowed;
}

.c-map-root a.leaflet-disabled:hover {
  background-color: var(--silver);
}

.c-map-root .leaflet-div-icon {
  background: none;
  border: 0;
}

.c-map-root .leaflet-container a {
  color: var(--azure);
}

.c-map-root .leaflet-container .leaflet-control-attribution {
  background: var(--black-transparent);
  color: var(--grey);
}
