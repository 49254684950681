.c-spot-card-root {
  text-align: center;
}

.c-spot-card-name {
  color: var(--white);
  font-size: clamp(1.8rem, 4vw, 2.4rem);
  font-weight: var(--semibold);
  margin: 2rem 0 2.4rem;
}

.c-spot-card-image {
  border-radius: 50%;
  height: clamp(13rem, 20vw, 20rem);
  margin: 0 auto;
  -o-object-fit: cover;
     object-fit: cover;
  width: clamp(13rem, 20vw, 20rem);
}

.c-spot-card-description {
  font-size: clamp(1.4rem, 2vw, 1.6rem);
  line-height: 1.6;
}
