.c-carousel-root {
  position: relative;
}

.c-carousel-root:focus-visible {
  outline: var(--focus-outline) !important;
}

.c-carousel-root::before,
.c-carousel-root::after {
  bottom: 0;
  content: "";
  display: block;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 20%;
  z-index: 1;
}

.c-carousel-root::after {
  background: linear-gradient(
    270deg,
    rgb(19 19 19) 19%,
    rgb(19 19 19 / 0%) 100%
  );
  right: 0;
}

.c-carousel-root::before {
  background: linear-gradient(
    90deg,
    rgb(19 19 19) 19%,
    rgb(19 19 19 / 0%) 100%
  );
  left: 0;
}

.c-carousel-cell {
  margin-right: 10rem;
  width: 45%;
}

/* Flickity */

.c-carousel-root .flickity-button {
  background: var(--azure);
  z-index: 2;
}

.c-carousel-root .flickity-button:hover {
  background: var(--azure);
}

.c-carousel-root .flickity-button:focus {
  box-shadow: none;
}

.c-carousel-root .flickity-button:focus-visible {
  outline: var(--focus-outline);
}

.c-carousel-root .flickity-prev-next-button {
  border-radius: 50%;
  height: 3.2rem;
  width: 3.2rem;
}

.c-carousel-root .flickity-prev-next-button .flickity-button-icon {
  height: 60%;
  left: 31%;
  position: absolute;
  top: 20%;
  width: 40%;
}

.c-carousel-root .flickity-button-icon {
  fill: white;
}

.c-carousel-root .flickity-prev-next-button.previous {
  left: 6rem;
}

.c-carousel-root .flickity-prev-next-button.next {
  right: 6rem;
}

/* Responsive */

@media (max-width: 768px) {
  .c-carousel-root {
    margin: 0 -1.5rem;
  }

  .c-carousel-root::before,
  .c-carousel-root::after {
    display: none;
  }

  .c-carousel-cell {
    width: 100%;
  }

  .c-carousel-root .flickity-prev-next-button.previous {
    left: 2rem;
  }

  .c-carousel-root .flickity-prev-next-button.next {
    right: 2rem;
  }
}

@media (max-width: 480px) {
  .c-carousel-root .flickity-prev-next-button.previous {
    left: 0;
  }

  .c-carousel-root .flickity-prev-next-button.next {
    right: 0;
  }
}
