/* Use component-local class names and add them to HTML via #class_for(name) helper */

.c-form--group-root {
  line-height: 1.25;
  margin-bottom: 3.2rem;
  max-width: 80rem;
}

.c-form--group-heading {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}

.c-form--group-label {
  display: block;
  font-size: clamp(1.6rem, 2vw, 1.8rem);
  font-weight: var(--semibold);
  margin-bottom: 1.2rem;
}

.c-form--group-hint {
  display: block;
  font-size: 0.8em;
  margin-bottom: 1.2rem;
}

.c-form--group-label + .c-form--group-hint {
  margin-top: -0.6rem;
}

.c-form--group-required {
  color: var(--azure);
}

.c-form--group-errors {
  color: var(--tomato);
}

.c-form--group-required,
.c-form--group-errors {
  font-size: 1.4rem;
  margin-left: 2rem;
}

.c-form--group-root input[type="datetime-local"],
.c-form--group-root input[type="email"],
.c-form--group-root input[type="number"],
.c-form--group-root input[type="password"],
.c-form--group-root input[type="tel"],
.c-form--group-root input[type="text"],
.c-form--group-root input[type="url"],
.c-form--group-root select,
.c-form--group-root textarea {
  background-color: var(--white-transparent);
  border: none;
  border-radius: 1rem;
  color: var(--white);
  height: 6rem;
  padding: 1rem 2.4rem;
  width: 100%;
}

.c-form--group-root textarea {
  height: 24rem;
  padding: 1.8rem 2.4rem;
  width: 100%;
}

/* Custom select */

.c-form--group-root select {
  --ms-expand: none;
  --webkit-appearance: none;

  -webkit-appearance: none;

     -moz-appearance: none;

          appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 6l4 4 4-4'/%3E%3C/svg%3E");
  background-position: right 1rem center;
  background-repeat: no-repeat;
  background-size: 2.2rem;
}

.c-form--group-root select::-moz-placeholder {
  color: var(--cerebral-grey);
}

.c-form--group-root select::placeholder {
  color: var(--cerebral-grey);
}

/* Pseudo classes */

.c-form--group-root input:focus,
.c-form--group-root select:focus,
.c-form--group-root textarea:focus {
  box-shadow: 0 0 0 0.2rem var(--azure);
  outline: none;
}

.c-form--group-root input:disabled,
.c-form--group-root select:disabled,
.c-form--group-root textarea:disabled {
  background-color: var(--cerebral-grey);
  color: var(--white);
  cursor: not-allowed;
}

/* Placeholders  */

.c-form--group-root input[type="datetime-local"]::-moz-placeholder, .c-form--group-root input[type="email"]::-moz-placeholder, .c-form--group-root input[type="number"]::-moz-placeholder, .c-form--group-root input[type="password"]::-moz-placeholder, .c-form--group-root input[type="tel"]::-moz-placeholder, .c-form--group-root input[type="text"]::-moz-placeholder, .c-form--group-root input[type="url"]::-moz-placeholder, .c-form--group-root textarea::-moz-placeholder {
  color: var(--cerebral-grey);
  font-family: var(--font-family);
}

.c-form--group-root input[type="datetime-local"]::placeholder,
.c-form--group-root input[type="email"]::placeholder,
.c-form--group-root input[type="number"]::placeholder,
.c-form--group-root input[type="password"]::placeholder,
.c-form--group-root input[type="tel"]::placeholder,
.c-form--group-root input[type="text"]::placeholder,
.c-form--group-root input[type="url"]::placeholder,
.c-form--group-root textarea::placeholder {
  color: var(--cerebral-grey);
  font-family: var(--font-family);
}

.c-form--group-root input::-moz-placeholder {
  opacity: 0.5;
}

.c-form--group-root input::placeholder {
  opacity: 0.5;
}
