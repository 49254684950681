.c-islands-list-root {
  background-color: var(--log-cabin-transparent);
  border-radius: 1rem;
  padding: 4rem;
  scroll-margin: 6.5rem;
}

/* Params */

.c-islands-list-title {
  color: var(--white);
  font-size: 2.4rem;
  font-size: clamp(1.8rem, 5vw, 2.4rem);
  font-weight: var(--semibold);
  line-height: 1.3;
  margin-top: 0;
}

.c-islands-list-lists {
  border-top: 1px solid var(--tundora);
  padding-top: 2.4rem;
}

.c-islands-list--archipelago-root {
  margin: 0;
}

.c-islands-list--archipelago-title {
  color: var(--white);
  font-size: clamp(1.6rem, 5vw, 1.8rem);
  font-weight: var(--semibold);
  margin-bottom: 1.3rem;
}

.c-islands-list--archipelago-title a {
  text-decoration: none;
}

.c-islands-list--archipelago-items {
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-islands-list--island-root + .c-islands-list--island-root {
  margin-top: 0.7rem;
}

.c-islands-list--island-link {
  border-radius: 0.4rem;
  color: var(--cerebral-grey);
  margin-left: -0.6rem;
  margin-top: 0.5rem;
  padding: 0.2rem 0.6rem;
  text-decoration: none;
}

.c-islands-list--island-link.c-islands-list--island-active {
  background-color: var(--white-transparent);
}

.c-islands-list--island-cross {
  --icon-color: var(--azure);
  --icon-size: 0.9rem;

  margin-left: 1rem;
}

/* format vertical */

.c-islands-list-root.c-islands-list-format-vertical {
  -webkit-backdrop-filter: blur(2rem) brightness(0.4);
          backdrop-filter: blur(2rem) brightness(0.4);
}

.c-islands-list-root.c-islands-list-format-vertical .c-islands-list-title {
  margin-bottom: 3.2rem;
}

.c-islands-list-root.c-islands-list-format-vertical .c-islands-list-lists {
  display: flex;
  flex-direction: column;
  padding-top: 3.2rem;
}

.c-islands-list-root.c-islands-list-format-vertical .c-islands-list--archipelago-root + .c-islands-list--archipelago-root {
  margin-top: 3rem;
}

/* format default */

.c-islands-list-root.c-islands-list-format-default {
  margin-bottom: 6rem;
}

.c-islands-list-root.c-islands-list-format-default .c-islands-list-title {
  margin-bottom: 2.4rem;
}

.c-islands-list-root.c-islands-list-format-default .c-islands-list-lists {
  grid-column-gap: 2rem;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(21rem, 1fr));
  grid-row-gap: 3rem;
  row-gap: 3rem;
}

/* media queries */

@media (min-width: 576px) {
  .c-islands-list-root.c-islands-list-format-vertical {
    left: 6rem;
    position: absolute;
    top: 12rem;
    width: 28.7rem;
    z-index: 401;
  }
}

@media (max-width: 576px) {
  .c-islands-list-root.c-islands-list-format-vertical {
    margin-top: 6rem;
  }

  .c-islands-list-root.c-islands-list-format-default {
    padding: 4rem 2rem;
  }
}
