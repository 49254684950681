/* Use component-local class names and add them to HTML via #class_for(name) helper */

.c-breadcrumbs-root {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  font-size: 1.6rem;
  font-weight: var(--regular);
  text-decoration: none;
}

.c-breadcrumbs-page {
  align-items: center;
  display: flex;
  flex-direction: row;
  line-height: 1.5;
}

.c-breadcrumbs-arrow {
  --icon-color: var(--azure);
  --icon-size: 1.2rem;

  margin: 0 1.6rem;
}

.c-breadcrumbs-page:last-child .c-breadcrumbs-arrow {
  display: none;
}

.c-breadcrumbs-page:last-child,
.c-breadcrumbs-page:last-child a {
  color: var(--white);
  font-weight: var(--bold);
}

.c-breadcrumbs--item-link {
  padding: 0;
  text-decoration: none;
}

.c-breadcrumbs--item-link:hover {
  color: var(--white);
}
