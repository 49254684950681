.c-feature-root {
  display: grid;
  grid-row: span 3;
  grid-template-rows: subgrid;
  grid-row-gap: 2.4rem;
  row-gap: 2.4rem;
}

.c-feature-title {
  color: var(--white);
  font-size: 2.4rem;
  font-weight: var(--semibold);
  margin: 0;
}

.c-feature-icon {
  --icon-size: 7rem;

  border-radius: 1.6rem;
  color: var(--white);
  padding: 1.4rem;
}

.c-feature-description {
  line-height: 1.6;
  margin: 0;
}

/* modifiers */

.c-feature-root .c-feature-icon.c-feature-green {
  background-color: var(--shamrock);
}

.c-feature-root .c-feature-icon.c-feature-blue {
  background-color: var(--azure);
}

.c-feature-root .c-feature-icon.c-feature-yellow {
  background-color: var(--golden-tainoi);
}

/* responsive */

@media (max-width: 768px) {
  .c-feature-root {
    grid-template-rows: none;
    row-gap: 1.4rem;
    width: 70%;
  }

  .c-feature-title {
    font-size: clamp(1.6rem, 5vw, 1.8rem);
  }

  .c-feature-description {
    font-size: clamp(1.2rem, 5vw, 1.4rem);
  }

  .c-feature-icon {
    grid-area: 1 / 1 / 2 / 2;
  }

  .c-feature-green {
    border-left: 0.2rem solid var(--shamrock);
    padding-left: 1.4rem;
  }

  .c-feature-blue {
    border-left: 0.2rem solid var(--azure);
    padding-left: 1.4rem;
  }

  .c-feature-yellow {
    border-left: 1px solid var(--golden-tainoi);
    padding-left: 1.4rem;
  }
}

@media (max-width: 576px) {
  .c-feature-root {
    width: 100%;
  }
}
